// src/components/Register.js

import React, { useState } from 'react';
import { register } from '../services/authService';  // Using the authService for API calls

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleRegister = (e) => {
        e.preventDefault();
        register(username, email, password)
            .then(response => {
                console.log("User registered successfully:", response.data);
            })
            .catch(error => {
                console.error("There was an error registering the user!", error);
            });
    };

    return (
        <form onSubmit={handleRegister}>
            <div>
                <label>Username:</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div>
                <label>Email:</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
                <label>Password:</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button type="submit">Register</button>
        </form>
    );
}

export default Register;
