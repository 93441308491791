// src/services/authService.js

import axios from 'axios';

const API_URL = 'http://your-django-api-url/';  // Make sure this matches your Django backend

export const register = (username, email, password) => {
    return axios.post(`${API_URL}register/`, {
        username,
        email,
        password
    });
};

export const login = (username, password) => {
    return axios.post(`${API_URL}login/`, {
        username,
        password
    });
};

// Add other auth functions like fetching user details, logging out, etc., as needed
